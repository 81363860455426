var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:`extended-data-tile-organism is--variant-${_vm.variant}`},[(_vm.description)?_c('div',{staticClass:"data-tile-header"},[_c('EsyH2',[_c('strong',[_vm._v(_vm._s(_vm.headline))])]),_vm._v(" "),_c('EsyParagraph',[_vm._v(" "+_vm._s(_vm.description)+" ")])],1):_c('EsyH3',[_c('strong',[_vm._v(_vm._s(_vm.headline))])]),_vm._v(" "),_c('div',{staticClass:"single-data-container"},[_c('EsyParagraph',{staticClass:"price-label",attrs:{"variant":"small"}},[_vm._v(" Heute ")]),_vm._v(" "),_c('div',{staticClass:"price"},[_c('EsyParagraph',[_vm._v(_vm._s(_vm.returnPriceFormatDE(_vm.data.currentPrice.value)))]),_vm._v(" "),_c('Icon',{class:`is--trend-${_vm.returnTrendDirectionString(_vm.data.relativeChange)}`,attrs:{"icon":`arrow-circle-${_vm.returnTrendDirectionString(
          _vm.data.relativeChange
        )}-solid.svg`}})],1),_vm._v(" "),_c('div',{staticClass:"change-time-value-container"},[_c('EsyParagraph',{staticClass:"update-time",attrs:{"variant":"small"}},[_c('Icon',{attrs:{"icon":"history-solid.svg"}}),_vm._v(" "+_vm._s(_vm.daydate.time)+" Uhr\n      ")],1),_vm._v(" "),_c('div',{class:`relative-change-container is--trend-${_vm.returnTrendDirectionString(
          _vm.data.relativeChange
        )}`},[_c('EsyParagraph',[_vm._v("\n          "+_vm._s(_vm.getAbsoluteChange(_vm.data.currentPrice.value, _vm.data.lastPrice.value))+"\n          /\n          "+_vm._s(_vm.getFormattedPercentage(_vm.data.currentPrice.value, _vm.data.lastPrice.value))+"\n        ")])],1)],1)],1),_vm._v(" "),_c('div',{staticClass:"last-change-container"},[_c('div',{staticClass:"row"},[_c('EsyParagraph',[_c('strong',[_vm._v("Gestern")])]),_vm._v(" "),_c('div',{staticClass:"last-change-price"},[_c('EsyParagraph',[_c('strong',[_vm._v(_vm._s(_vm.returnPriceFormatDE(_vm.data.lastPrice.value)))])])],1)],1)]),_vm._v(" "),_c('ul',{staticClass:"recommendation-container"},[_c('ListItem',{class:`is--buying-intension-index-${this.$store.state.schwarmometer.esyoilSignal.buyingIntesionDirection}`},[_c('Icon',{staticClass:"indicator-icon",attrs:{"icon":_vm.returnPriceSystemDirectionIcon(
            this.$store.state.schwarmometer.esyoilSignal.buyingIntesionDirection
          )}}),_vm._v(" "),_c('EsyParagraph',{staticClass:"swarm-anker-tag",nativeOn:{"click":function($event){return _vm.jumpToSection()}}},[_vm._v("\n        esyoil-Kaufempfehlung\n        "),_c('strong',[_vm._v(_vm._s(this.$store.state.schwarmometer.esyoilSignal.buyingIntesionString))])])],1),_vm._v(" "),(_vm.$store.state.schwarmometer.userSignal.buyingIntesionIndex)?_c('ListItem',{class:`is--buying-intension-index-${this.$store.state.schwarmometer.userSignal.buyingIntesionIndex}`},[_c('Icon',{staticClass:"indicator-icon",attrs:{"icon":_vm.returnSwarmDirectionIcon(
            _vm.$store.state.schwarmometer.userSignal.buyingIntesionIndex
          )}}),_vm._v(" "),_c('EsyParagraph',{staticClass:"swarm-anker-tag",nativeOn:{"click":function($event){return _vm.jumpToSection()}}},[_vm._v("\n        Schwarm-O-Meter\n        "),_c('strong',[_vm._v(_vm._s(this.$store.state.schwarmometer.userSignal.buyingIntesionString))])])],1):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"trusted-elements-container"},[_c('RouterLink',{staticClass:"trusted-elements-link",attrs:{"title":"Zu den Charts","href":{ path: '/infos/qualitaetsmanagement' },"externalLink":false,"underline":true}},[_c('EsyImg',{staticClass:"highlight-box",attrs:{"src":require('~/assets/images/certificates/tuev-siegel.svg'),"alt":"Tüv-Siegel","width":"100","height":"100"}})],1),_vm._v(" "),_c('SummedRating',{key:this.companyRating.ratingValue,staticClass:"ratings-component",attrs:{"ratingValue":this.companyRating.ratingValue,"ratingCount":this.companyRating.ratingCount}})],1),_vm._v(" "),(_vm.data.currentPrice.value == 0)?_c('LoadingSpinner',{staticClass:"loading-spinner",attrs:{"timelimitMiliseconds":10000,"loadingFailedMessage":"Beim laden der Daten ist etwas schief gelaufen, bitte versuchen Sie es zu einem späteren Zeitpunkt noch einmal."}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }