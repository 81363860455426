
// Libraries
import { Component, Vue, Prop } from "nuxt-property-decorator";
import { ButtonText } from "~/operations/messages";

// Components
import ProgressBar from "~/components/molecules/display/ProgressBar.vue";

type BooleanNumber = 0 | 1;

@Component({
  components: {
    ProgressBar,
  },
})
export default class PriceTrendVote extends Vue {
  @Prop({ default: false }) activeUserVote: boolean;
  @Prop() votingDate: string;
  @Prop() votingQuantity: number;
  @Prop() positiveVotesPercentage: number;
  @Prop() negativeVotesPercentage: number;
  @Prop() votingID: string;

  ButtonText = ButtonText;

  postUserPriceTrendPrognosis(userPriceTrendPrognosis: BooleanNumber) {
    if (userPriceTrendPrognosis === null) return;
    this.$store.dispatch("esyoil/postPricePredictionVoting", {
      id: this.votingID,
      voteBooleanNumber: userPriceTrendPrognosis,
    });
  }

  userPrognosisText() {
    if (this.positiveVotesPercentage === this.negativeVotesPercentage)
      return "Unsere Leser prognostizieren eine tendenzielle Seitwärtsbewegung.";
    if (this.positiveVotesPercentage > this.negativeVotesPercentage)
      return "Unsere Leser prognostizieren eine tendenzielle Preissenkung.";
    if (this.positiveVotesPercentage < this.negativeVotesPercentage)
      return "Unsere Leser prognostizieren eine tendenzielle Preissteigerung.";
  }
}
